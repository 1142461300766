/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.2
 *
 */

$(document).ready(function() {
  // Add body-small class if window less than 768px
  if ($(this).width() < 769) {
    $('body').addClass('body-small')
  } else {
    $('body').removeClass('body-small')
  }

  // Collapse ibox function
  $('.collapse-link').click(function() {
    var ibox = $(this).closest('div.ibox')
    var button = $(this).find('i')
    var content = ibox.find('div.ibox-content')
    content.slideToggle(200)
    button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down')
    ibox.toggleClass('').toggleClass('border-bottom')
    setTimeout(function() {
      ibox.resize()
      ibox.find('[id^=map-]').resize()
    }, 50)
  })

  // Close ibox function
  $('.close-link').click(function() {
    var content = $(this).closest('div.ibox')
    content.remove()
  })

  // Open close right sidebar
  $('.right-sidebar-toggle').click(function() {
    $('#right-sidebar').toggleClass('sidebar-open')
  })

  // Initialize slimscroll for right sidebar
  $('.sidebar-container').slimScroll({
    height: '100%',
    railOpacity: 0.4,
    wheelStep: 10,
  })

  // Small todo handler
  $('.check-link').click(function() {
    var button = $(this).find('i')
    var label = $(this).next('span')
    button.toggleClass('fa-check-square').toggleClass('fa-square-o')
    label.toggleClass('todo-completed')
    return false
  })

  // Tooltips demo
  $('.tooltip-demo').tooltip({
    selector: '[data-toggle=tooltip]',
    container: 'body',
  })

  // Move modal to body
  // Fix Bootstrap backdrop issu with animation.css
  $('.modal').appendTo('body')

  // Full height of sidebar
  function fix_height() {
    var heightWithoutNavbar = $('body > #wrapper').height() - 61
    $('.sidebard-panel').css('min-height', heightWithoutNavbar + 'px')

    var navbarHeigh = $('nav.navbar-default').height()
    var wrapperHeigh = $('#page-wrapper').height()

    if (navbarHeigh > wrapperHeigh) {
      $('#page-wrapper').css('min-height', navbarHeigh + 'px')
    }

    if (navbarHeigh < wrapperHeigh) {
      $('#page-wrapper').css('min-height', $(window).height() + 'px')
    }

    if ($('body').hasClass('fixed-nav')) {
      $('#page-wrapper').css('min-height', $(window).height() - 60 + 'px')
    }
  }
  fix_height()

  // Fixed Sidebar
  $(window).bind('load', function() {
    if ($('body').hasClass('fixed-sidebar')) {
      $('.sidebar-collapse').slimScroll({
        height: '100%',
        railOpacity: 0.9,
      })
    }
  })

  // Move right sidebar top after scroll
  $(window).scroll(function() {
    if ($(window).scrollTop() > 0 && !$('body').hasClass('fixed-nav')) {
      $('#right-sidebar').addClass('sidebar-top')
    } else {
      $('#right-sidebar').removeClass('sidebar-top')
    }
  })

  $(window).bind('load resize scroll', function() {
    if (!$('body').hasClass('body-small')) {
      fix_height()
    }
  })

  $('[data-toggle=popover]').popover()

  // Add slimscroll to element
  $('.full-height-scroll').slimscroll({
    height: '100%',
  })
})

// Minimalize menu when screen is less than 768px
$(window).bind('resize', function() {
  if ($(this).width() < 769) {
    $('body').addClass('body-small')
  } else {
    $('body').removeClass('body-small')
  }
})

// For demo purpose - animation css script
function animationHover(element, animation) {
  element = $(element)
  element.hover(
    function() {
      element.addClass('animated ' + animation)
    },
    function() {
      //wait for animation to finish before removing classes
      window.setTimeout(function() {
        element.removeClass('animated ' + animation)
      }, 2000)
    }
  )
}
